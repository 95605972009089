import { Answer, Answers, Response } from "@/models/Types";
import A1Info from "@/content/answers/1/A1Info.vue";
import A1 from "@/content/answers/1/A1.vue";
import A2 from "@/content/answers/2/A2.vue";
import A2Info from "@/content/answers/2/A2Info.vue";
import A3Info from "@/content/answers/3/A3Info.vue";
import A3 from "@/content/answers/3/A3.vue";
import A4 from "@/content/answers/4/A4.vue";
import A4Info from "@/content/answers/4/A4Info.vue";
import A5Info from "@/content/answers/5/A5Info.vue";
import A5 from "@/content/answers/5/A5.vue";
import A6Info from "@/content/answers/6/A6Info.vue";
import A6 from "@/content/answers/6/A6.vue";
import A7Info from "@/content/answers/7/A7Info.vue";
import A7 from "@/content/answers/7/A7.vue";
import A8Info from "@/content/answers/8/A8Info.vue";
import A8 from "@/content/answers/8/A8.vue";
import A9Info from "@/content/answers/9/A9Info.vue";
import A9 from "@/content/answers/9/A9.vue";
import A10Info from "@/content/answers/10/A10Info.vue";
import A10 from "@/content/answers/10/A10.vue";
import A11 from "@/content/answers/11/A11.vue";
import A11Info from "@/content/answers/11/A11Info.vue";
import A12Info from "@/content/answers/12/A12Info.vue";
import A12 from "@/content/answers/12/A12.vue";
import A13Info from "@/content/answers/13/A13Info.vue";
import A13 from "@/content/answers/13/A13.vue";
import A14Info from "@/content/answers/14/A14Info.vue";
import A14 from "@/content/answers/14/A14.vue";
import A15Info from "@/content/answers/15/A15Info.vue";
import A15 from "@/content/answers/15/A15.vue";
import A16Info from "@/content/answers/16/A16Info.vue";
import A16 from "@/content/answers/16/A16.vue";

const Answer1: Answer = {
  furtherInfo: A1Info,
  content: A1,
  hasTreatment: true,
  id: "a1",
  type: Response.Answer,
};

const Answer2: Answer = {
  furtherInfo: A2Info,
  content: A2,
  hasTreatment: true,
  id: "a2",
  type: Response.Answer,
};

const Answer3: Answer = {
  furtherInfo: A3Info,
  content: A3,
  hasTreatment: false,
  id: "a3",
  type: Response.Answer,
};

const Answer4: Answer = {
  furtherInfo: A4Info,
  content: A4,
  hasTreatment: true,
  id: "a4",
  type: Response.Answer,
};

const Answer5: Answer = {
  furtherInfo: A5Info,
  content: A5,
  hasTreatment: true,
  id: "a5",
  type: Response.Answer,
};

const Answer6: Answer = {
  furtherInfo: A6Info,
  content: A6,
  hasTreatment: true,
  id: "a6",
  type: Response.Answer,
};

const Answer7: Answer = {
  furtherInfo: A7Info,
  content: A7,
  hasTreatment: false,
  id: "a7",
  type: Response.Answer,
};

const Answer8: Answer = {
  furtherInfo: A8Info,
  content: A8,
  hasTreatment: false,
  id: "a8",
  type: Response.Answer,
};

const Answer9: Answer = {
  furtherInfo: A9Info,
  content: A9,
  hasTreatment: true,
  id: "a9",
  type: Response.Answer,
};

const Answer10: Answer = {
  furtherInfo: A10Info,
  content: A10,
  hasTreatment: true,
  id: "a10",
  type: Response.Answer,
};

const Answer11: Answer = {
  furtherInfo: A11Info,
  content: A11,
  hasTreatment: true,
  id: "a11",
  type: Response.Answer,
};

const Answer12: Answer = {
  furtherInfo: A12Info,
  content: A12,
  hasTreatment: true,
  id: "a12",
  type: Response.Answer,
};

const Answer13: Answer = {
  furtherInfo: A13Info,
  content: A13,
  hasTreatment: false,
  id: "a13",
  type: Response.Answer,
};

const Answer14: Answer = {
  furtherInfo: A14Info,
  content: A14,
  hasTreatment: false,
  id: "a14",
  type: Response.Answer,
};

const Answer15: Answer = {
  furtherInfo: A15Info,
  content: A15,
  hasTreatment: false,
  id: "a15",
  type: Response.Answer,
};

const Answer16: Answer = {
  furtherInfo: A16Info,
  content: A16,
  hasTreatment: false,
  id: "a16",
  type: Response.Answer,
};

const Answers: Answers = {
  a1: Answer1,
  a2: Answer2,
  a3: Answer3,
  a4: Answer4,
  a5: Answer5,
  a6: Answer6,
  a7: Answer7,
  a8: Answer8,
  a9: Answer9,
  a10: Answer10,
  a11: Answer11,
  a12: Answer12,
  a13: Answer13,
  a14: Answer14,
  a15: Answer15,
  a16: Answer16,
};

export default Answers;
