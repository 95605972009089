



























































import Vue, { Component } from "vue";
import BhSection from "../../components/BhSection.vue";
import { mapActions, mapGetters } from "vuex";
import { AlgorithmOption, Question, Response, Selection } from "@/models/Types";

export default Vue.extend({
  name: "Question",
  metaInfo: {
    title: "Questions",
  },
  components: { BhSection },
  props: {
    oid: {
      type: [String],
    },
  },
  computed: {
    ...mapGetters(["allOptions", "previousOption"]),
    question(): Question {
      return this.allOptions[this.oid];
    },
    content(): Component | null {
      return this.question?.content ?? null;
    },
    extraInfo(): Component | null {
      return this.question?.furtherInfo ?? null;
    },
    yes(): Selection {
      return Selection.Yes;
    },
    no(): Selection {
      return Selection.No;
    },
    buttonOne(): string {
      if (!this.question?.buttons?.length) return "Yes";
      return this.question?.buttons[0] ?? "Yes";
    },
    buttonTwo(): string {
      if (!this.question?.buttons?.length) return "No";
      return this.question?.buttons[1] ?? "No";
    },
  },
  methods: {
    ...mapActions(["gotToNextOption"]),
    async answer(selection: Selection) {
      const result = {
        optionId: this.question.edges[selection],
        selection: selection,
      };
      const nextOption: AlgorithmOption = await this.gotToNextOption(result);
      if (nextOption.type === Response.Question) {
        const oid = nextOption.id;
        await this.$router.push({ name: "Questions", params: { oid } });
        return;
      }

      if (nextOption.type === Response.Answer) {
        const oid = nextOption.id;
        await this.$router.push({ name: "Answers", params: { oid } });
        return;
      }
    },
  },
});
