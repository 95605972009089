




























import Vue from "vue";
import BhSection from "@/components/BhSection.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Response } from "@/models/Types";
import { Mutations } from "@/store";
import { JobCodes } from "@/store/jobCodes";

export default Vue.extend({
  name: "Algorithm",
  components: { BhSection },
  computed: {
    ...mapGetters(["previousOption"]),
  },
  created() {
    this.setJobCode(JobCodes.ALGORITHM);
  },
  methods: {
    ...mapMutations({ setJobCode: Mutations.SET_JOB_CODE }),
    ...mapActions(["goToPreviousOption", "resetOptions"]),
    async onBack() {
      const option = await this.goToPreviousOption();
      if (option.type === Response.Question) {
        const oid = option.id;
        await this.$router.push({ name: "Questions", params: { oid } });
        return;
      }

      if (option.type === Response.Answer) {
        const oid = option.id;
        await this.$router.push({ name: "Answers", params: { oid } });
        return;
      }
    },
    async onReset() {
      this.resetOptions();
      await this.$router.push({ name: "AlgorithmHome" });
    },
  },
});
