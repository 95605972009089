






































































import Vue from "vue";
import BhSection from "@/components/BhSection.vue";
import PiFultiumD3 from "@/views/PrescribingInformation/PiFultiumD3.vue";
import PiFultiumD3Drops from "@/views/PrescribingInformation/PiFultiumD3Drops.vue";
import PiMovymia from "@/views/PrescribingInformation/PiMovymia.vue";
import PiBinosto from "@/views/PrescribingInformation/PiBinosto.vue";
import PiAccreteD3 from "@/views/PrescribingInformation/PiAccreteD3.vue";
import { mapMutations } from "vuex";
import { Mutations } from "@/store";
import { JobCodes } from "@/store/jobCodes";

export default Vue.extend({
  name: "PrescribingInformation",
  metaInfo: {
    title: "Prescribing Information",
  },
  components: {
    PiAccreteD3,
    PiBinosto,
    PiMovymia,
    PiFultiumD3Drops,
    PiFultiumD3,
    BhSection,
  },
  methods: {
    ...mapMutations({ setJobCode: Mutations.SET_JOB_CODE }),
  },
  created() {
    this.setJobCode(JobCodes.PRESCRIBING_INFORMATION);
  },
});
