












































































































import Vue from "vue";
import BhSection from "@/components/BhSection.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "TheFooterContent",
  components: { BhSection },
  computed: {
    ...mapGetters(["jobCode"]),
  },
});
