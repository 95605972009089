
















































































import Vue, { Component } from "vue";
import BhSection from "@/components/BhSection.vue";
import { mapGetters, mapMutations } from "vuex";
import { Answer, Selection } from "@/models/Types";
import { Mutations } from "@/store";

export default Vue.extend({
  name: "Answer",
  metaInfo: {
    title: "Result",
  },
  components: { BhSection },
  props: {
    oid: {
      type: [String],
    },
  },
  computed: {
    ...mapGetters(["thisOption", "allOptions", "previousOption"]),
    question(): Answer {
      return this.allOptions[this.oid];
    },
    content(): Component | null {
      return this.question?.content ?? null;
    },
    extraInfo(): Component | null {
      return this.question?.furtherInfo ?? null;
    },
  },
  methods: {
    ...mapMutations({ append: Mutations.APPEND_PREVIOUS_OPTION }),
    async addAnswerToPath(): Promise<void> {
      this.append({ option: this.thisOption, selection: Selection.No });
      await this.$router.push({ name: "Review" });
    },
  },
});
