



















import Vue, { Component } from "vue";
import BhSection from "@/components/BhSection.vue";
import { mapGetters } from "vuex";
import { AlgorithmOption, Question, Response, Selection } from "@/models/Types";

export default Vue.extend({
  name: "Review",
  metaInfo: {
    title: "Review",
  },
  components: { BhSection },
  computed: {
    ...mapGetters(["getResults"]),
    results: function (): { question: Component; answer: string }[] {
      return this.getResults
        .filter(
          (value: { option: AlgorithmOption; selection: Selection }) =>
            value.option.type === Response.Question
        )
        .map(
          ({
            option,
            selection,
          }: {
            option: Question;
            selection: Selection;
          }) => {
            const question = option.content;
            let answer = "";
            if (option.buttons) {
              answer = option.buttons[selection];
            } else {
              answer = selection === Selection.Yes ? "Yes" : "No";
            }
            return { question, answer };
          }
        );
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.getResults.length) {
      next({ name: "Algorithm" });
    } else {
      next();
    }
  },
});
