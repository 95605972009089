





























import Vue from "vue";
import BhSection from "../../components/BhSection.vue";
import FultiumD3 from "./FultiumD3.vue";
import Binosto from "./Binosto.vue";
import AccreteD3 from "./AccreteD3.vue";
import Movymia from "./Movymia.vue";
import { mapMutations } from "vuex";
import { Mutations } from "@/store";
import { JobCodes } from "@/store/jobCodes";

export default Vue.extend({
  name: "Products",
  metaInfo: {
    title: "Products",
  },
  components: { Movymia, AccreteD3, Binosto, FultiumD3, BhSection },
  methods: {
    ...mapMutations({ setJobCode: Mutations.SET_JOB_CODE }),
  },
  created() {
    this.setJobCode(JobCodes.PRODUCTS);
  },
});
