import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Question from "@/views/Algorithm/Question.vue";
import Algorithm from "@/views/Algorithm/Algorithm.vue";
import Answer from "@/views/Algorithm/Answer.vue";
import AlgorithmHome from "@/views/Algorithm/AlgorithmHome.vue";
import Review from "@/views/Algorithm/Review.vue";
import PrescribingInformation from "@/views/PrescribingInformation/PrescribingInformation.vue";
import Products from "@/views/Products/Products.vue";
import Contributors from "@/views/Contributors.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/products",
    name: "Products",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Products,
  },
  {
    path: "/contributors",
    name: "Contributors",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Contributors,
  },
  {
    path: "/prescribing-information",
    name: "PrescribingInformation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PrescribingInformation,
  },
  {
    path: "/algorithm",
    name: "Algorithm",
    component: Algorithm,
    redirect: { name: "AlgorithmHome" },
    children: [
      {
        path: "",
        name: "AlgorithmHome",
        component: AlgorithmHome,
      },
      {
        path: "questions",
        redirect: { name: "Home" },
      },
      {
        path: "questions/:oid",
        name: "Questions",
        props: true,
        component: Question,
        beforeEnter(to, from, next) {
          const { oid } = to.params;
          if (oid) {
            next();
          } else {
            next({ name: "Home" });
          }
        },
      },
      {
        path: "answers/:oid",
        name: "Answers",
        props: true,
        component: Answer,
      },
      {
        path: "answers/review",
        name: "Review",
        component: Review,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line
  scrollBehavior: (to, from, savedPosition) => {
    return { x: 0, y: 0 };
  },
});

export default router;
