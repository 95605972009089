



















import Vue from "vue";
import { differenceInDays } from "date-fns";

export default Vue.extend({
  name: "TheCookieNotice",
  data: () => ({
    snackbar: false,
  }),
  created() {
    const acceptedCookiesString =
      window.localStorage.getItem("cookiesAccepted");
    if (acceptedCookiesString) {
      const cookiesAccepted = new Date(JSON.parse(acceptedCookiesString));
      const daysSinceLastAccepted = differenceInDays(
        cookiesAccepted,
        Date.now()
      );
      this.snackbar = daysSinceLastAccepted > 30;
    } else {
      this.snackbar = true;
    }
  },
  methods: {
    acceptCookies(): void {
      this.snackbar = false;
      window.localStorage.setItem(
        "cookiesAccepted",
        JSON.stringify(new Date())
      );
    },
  },
});
