<template>
  <div>
    <p>
      <strong
        >Fultium-D<sub>3</sub> Drops Abbreviated Prescribing Information</strong
      >
    </p>
    <p>
      Please refer to the Summary of Product Characteristics (SmPC) before
      prescribing Fultium-D<sub>3</sub> Drops. Use care when prescribing in
      pregnancy, as high doses of colecalciferol may affect the foetus.
    </p>
    <p>
      <strong>Fultium-D<sub>3</sub> Drops: </strong>1 ml of oral solution
      contains 2740 IU (68.5 mcg per ml) colecalciferol; 3 drops contains 200 IU
      colecalciferol
    </p>
    <p>
      <strong>In</strong><strong>dications: </strong>Prevention and treatment of
      vitamin D deficiency in adults and children, and as an adjunct to specific
      therapy for osteoporosis in patients with vitamin D deficiency or at risk
      of vitamin D insufficiency.
    </p>
    <p>
      <strong>Dosage and administration: </strong>For oral use. Can be taken
      directly or mixed with a small amount of food.
    </p>
    <p>
      <em>Adults:</em>Treatment of deficiency: 12 - 60 drops (800-4000 IU)
      daily; During pregnancy and breast-feeding: 6-60 drops(400-4000 IU) daily;
      Osteoporosis adjunctive therapy: 12 drops (800 IU) daily.Maintenance or
      prevention of deficiency: 12 - 24 drops (800-1600 IU) daily; During
      pregnancy and breast-feeding: 6-30 drops (400-2000 IU) daily.
    </p>
    <p>
      <em>Children:</em>Treatment of deficiency: 0-2 years: 6-15 drops (400-1000
      IU) daily; 2-11 years: 6-30 drops (400-2000 IU) daily; 12-18 years: 6-60
      drops (400-4000 IU) daily.Maintenance or prevention of deficiency: 0-2
      years: 3-15 drops (200-1000 IU) daily; 2-11 years: 6-15 drops (400-1000
      IU) daily; 12-18 years: 6-24 drops (400-1600 IU) daily.
    </p>
    <p>
      <strong>Contraindications: </strong>Hypersensitivity to vitamin D or any
      of the excipients; hypervitaminosis D; nephrolithiasis; diseases or
      conditions resulting in hypercalcaemia and/or hypercalciuria; severe renal
      impairment.
    </p>
    <p>
      <strong>Warnings and Precautions: </strong>Use caution in patients with
      impaired renal function or sarcoidosis. Monitor effect on calcium and
      phosphate levels in these patients. Consider risk of soft tissue
      calcification. Use other forms of vitamin D in cases of severe renal
      insufficiency. Consider the need for calcium supplementation in individual
      patients. Where calcium supplementation is necessary, close medical
      supervision is required. Use caution in patients receiving treatment for
      cardiovascular disease. Make allowances for vitamin D supplementation from
      other sources. Monitor to prevent hypercalcaemia.
    </p>
    <p>
      <strong>Interactions: </strong>Concomitant phenytoin, barbiturates and
      glucocorticoids can decrease the effect of vitamin D. Ion exchange resins,
      laxatives, actinomycin and imidazole may also reduce the effect of vitamin
      D. Oral calcium and vitamin D potentiates the effect of digitalis and
      other cardiac glycosides.
    </p>
    <p>
      <strong>Pregnancy and lactation: </strong>Limited clinical data in
      pregnancy. Animal studies have shown reproductive toxicity. RDI in
      pregnancy is 400 IU. Pregnant women who are vitamin D deficient may need a
      higher dose. Pregnant women should follow the advice of their GP, as their
      requirements may vary depending on disease severity and response to
      treatment. Vitamin D and metabolites are excreted in breast milk. Overdose
      in nursing infants has not been observed, however, when prescribing
      additional vitamin D to a breast-fed child, consider the maternal dose of
      any additional vitamin D.
    </p>
    <p>
      <strong>Undesirable effects: </strong>Hypercalcaemia and hypercalciuria.
      Refer to the SmPC for the full list of side effects.
    </p>
    <p><strong>Legal Category: </strong>POM</p>
    <p>
      <strong>Pack size: </strong>Fultium-D<sub>3</sub> Drops, 1 x 25 ml – NHS
      Price £10.70
    </p>
    <p><strong>MA Number: </strong>PL40861/0005</p>
    <p>
      <strong>MA Holder: </strong>Internis Pharmaceuticals Ltd. Linthwaite
      Laboratories, Linthwaite, Huddersfield, West Yorkshire HD7 5QH, UK
    </p>
    <p><em>Full Prescribing Information available.</em></p>
    <p><strong>Date of preparation</strong>: January 2023</p>
    <p><strong>Unique ID no:</strong> FULT-75(2)</p>
    <br />
    <div class="bd1 p0-25 tac">
      <p class="border-box">
        Adverse events should be reported. Reporting forms and information can
        be found at:
        <a href="https://yellowcard.mhra.gov.uk" target="_blank"
          >https://yellowcard.mhra.gov.uk</a
        >. Adverse events should also be reported to Thornton and Ross Limited
        by emailing
        <a href="mailto:thorntonross@medinformation.co.uk"
          >thorntonross@medinformation.co.uk</a
        >
        or by calling 01484 848164.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PiFultiumD3Drops",
};
</script>

<style scoped></style>
