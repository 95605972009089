














































































import Vue from "vue";
import BhSection from "@/components/BhSection.vue";
import TheHomeIntroText from "@/content/home/TheHomeIntroText.vue";
import { mapMutations } from "vuex";
import { Mutations } from "@/store";
import { JobCodes } from "@/store/jobCodes";

export default Vue.extend({
  name: "Home",
  metaInfo: {
    title: "Bone Health Treatment Algorithm",
    titleTemplate: "",
  },
  components: { TheHomeIntroText, BhSection },
  methods: {
    ...mapMutations({ setJobCode: Mutations.SET_JOB_CODE }),
  },
  created() {
    this.setJobCode(JobCodes.HOME);
  },
});
